


import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";

let env = process.env.NODE_ENV;

var devConfig = {
  apiKey: "AIzaSyCIlf-STxMA8nVoE6NWBZ1CmLLaHQwz4Ts",
  authDomain: "gpslatino-app.firebaseapp.com",
  projectId: "gpslatino-app",
  storageBucket: "gpslatino-app.appspot.com",
  messagingSenderId: "205569584618",
  appId: "1:205569584618:web:f9e75c59ead7e476ca1693",
  measurementId: "G-9PGZZ6WN7P"
};

var prodConfig = {
  apiKey: "AIzaSyCIlf-STxMA8nVoE6NWBZ1CmLLaHQwz4Ts",
  authDomain: "gpslatino-app.firebaseapp.com",
  projectId: "gpslatino-app",
  storageBucket: "gpslatino-app.appspot.com",
  messagingSenderId: "205569584618",
  appId: "1:205569584618:web:f9e75c59ead7e476ca1693",
  measurementId: "G-9PGZZ6WN7P"
};

firebase.initializeApp(env == "production" ? prodConfig : devConfig);

// if (env == "development") {
// firebase.functions().useFunctionsEmulator("http://localhost:5001");
// }

firebase.auth().languageCode = "es";

export const db = firebase.firestore();
export const fb = firebase;
