
<template>
  <footer>
    <v-container class="foo">
      <div class="logo mt-4">
        <img src="@/assets/LOGOGPSBl-01.svg" alt="" srcset="" />
        <div class="links-redes">
          <v-btn v-for="(i, index) in link" :key="index" icon dark :href="i.link" target="_blank" >
            <v-icon>
              {{ i.icon }}
            </v-icon>
          </v-btn>
          <v-btn icon dark href="https://twitter.com/GPSlatino_?s=20" target="_blank" >
            <i class="fa-brands fa-x-twitter"></i>
          </v-btn>
          <v-btn   icon dark href="https://www.tiktok.com/@gpslatino/video/7254357515096378667?_r=1&_t=8ev9g803iIs" target="_blank" >
            <i class="fa-brands fa-tiktok"></i>
          </v-btn>
        
        </div>
      </div>
      <div class="vtn-" >
        <v-btn large color="primary" @click="dialog = true" rounded>  {{  leng  ? 'Get in touch':'Ponte en contacto'}}   </v-btn>
      </div>
    </v-container>
    <div class="claimer"></div>
    <v-dialog v-model="dialog" v-if="dialog" width="500px">
      <formGetInTouch @close="dialog = false" />
      </v-dialog>
  </footer>
</template>
<script>
import { db, fb } from "@/firebase";
import inputPhone from "@/components/inputPhone.vue";
import { mapActions, mapState } from "vuex";
import formGetInTouch from "@/components/formGetinTouch.vue";
export default {
  name: "footerBar",
  components: {inputPhone,    formGetInTouch},
  data() {
    return {
      dialog: false,
      form: {},
      phone: null,
      code: null,
      link: [
        {
          icon: "mdi-instagram",
          link: "https://www.instagram.com/gpslatino_/?fbclid=IwAR2-q6zDc2TKEhKdN6gSpv8rMp4Orq6mxJzngEYqahqb9-QW_4BsWoLNlUE",
        },
        {
          icon: "mdi-facebook",
          link: "https://www.facebook.com/profile.php?id=100091356627027",
        },

      ],
    };
  },
  computed:{
    ...mapState(["language"]),
    leng(){
      return this.language == 'en'? true : false;
    }
  },
  methods: {
    valueInput(e, code) {
      // console.debug(e, code);
      this.phone = e;
      this.code = code;
    },
    send() {
      var data = Object.assign({}, this.form);
      var phone = `${this.code.callingCodes[0]}${this.phone}`;
      data.phone = phone;

      db.collection("usersPending")
        .add(data)
        .then((response) => {
          console.debug(response);
        })
        .catch((error) => {
          console.debug(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
footer {
  background-color: #094a7f;
  width: 100%;
  height: auto;
  .claimer {
    height: 20px;
    width: 100%;
    background-color: #f78c23;
  }
}
.logo {
  width: 150px;
}
h2 {
  background-clip: text;
  background-image: linear-gradient(#e66465, #9198e5);
  color: transparent;
  font-style: normal;
}
.foo{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>