<template>
  <v-card style="border-radius: 15px">
    <v-card-title> {{ lang ? 'Get in touch' : 'Ponerse en contacto' }} </v-card-title>
    <v-card-text>
      <v-text-field v-model="form.name" rounded name="name" :label="lang ? 'Name' : 'Nombre'" required
        filled></v-text-field>
      <inputPhone @retunValue="valueInput" @actions="send" />
      <v-text-field v-model="form.email" rounded name="email" :label="lang ? 'Email' : 'Correo'" required
        filled></v-text-field>
      <v-textarea v-model="form.comment" rounded name="comment" :label="lang ? 'Comment' : 'Comentario'" required
        filled></v-textarea>
    </v-card-text>
    <v-card-actions>
      <v-spacer> </v-spacer>
      <v-btn text @click="$emit('close')" rounded class="mr-4">
        {{ lang ? 'cancel' : 'Cancelar' }}
      </v-btn>
      <v-btn color="primary" class="px-12" rounded @click="send">
        {{ lang ? 'send' : 'Enviar' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
import alertMessage from "@/mixins/alertMixin";
import inputPhone from "@/components/inputPhone.vue";
export default {
  name: 'formGetInTouch',
  mixins: [alertMessage],
  components: {
    inputPhone,
  },
  data() {
    return {
      form: {}, 
      phone: null,
      code: null,
    }
  },
  computed: {
    ...mapState(["language"]),
    lang() {
      return this.language == "en" ? true : false;
    },
  },
  methods: {
    ...mapActions(["Alert_"]),
    valueInput(e, code) {
      // //console.debug(e, code);
      this.phone = e;
      this.code = code;
    },
    send() {
      var data = Object.assign({}, this.form);
      var phone = `${this.code.callingCodes[0]}${this.phone}`;
      data.phone = phone;
      console.debug(data);
      data.deleted = false;
      data.createdAt = new Date();
      console.debug(data)
      db.collection("usersPending")
        .add(data)
        .then((response) => {
          console.debug(response);
          this.alertMessage("success", this.lang ? "Record saved successfully" : 'Registro guardado correctamente');
          this.phone = null;
          this.code = null;
          this.form = {};
          this.$emit('close')

        })
        .catch((error) => {
          console.debug(error);
          this.alertMessage("error", "unexpected error occurred");
          this.phone = null;
          this.code = null;
          this.form = {};
          this.$emit('close')
        });
    },
  }


}

</script>