<template>
  <v-app>
    <preloader />
    <Snackbar style="z-index: 1000; width: 100%; position: fixed" />
    <menuBar @openDialog="dialogLenguaje = true" />
    <v-main>
      <router-view />
    </v-main>
    <footerBar />
    <v-dialog  v-model="dialogLenguaje" v-if="dialogLenguaje"  persistent width="250px">
      <v-card >
        <v-card-title>
          Select language <v-spacer></v-spacer>  <v-icon class="ml-2">
              mdi-translate
            </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center pa-12">
      
          <v-btn-toggle
          v-model="toogle"
          color="primary"
          rounded
        >
          <v-btn value="es"  >
            ES
            <v-icon small class="ml-2">
              mdi-translate
            </v-icon>
          </v-btn>
          <v-btn  value="en">
            EN
            <v-icon small class="ml-2">
              mdi-translate
            </v-icon>
          </v-btn>
          
        </v-btn-toggle>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" block @click="setLenguaje">
            aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Snackbar from "@/components/snackbar";
import menuBar from "@/components/menu.vue";
import footerBar from "@/components/footer.vue";
import preloader from "@/components/preloader.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "App",
  components: {
    menuBar,
    Snackbar,
    footerBar,
    preloader
  },
  data: () => ({
    dialogLenguaje: false,
    toogle:null,
    //
  }),
  computed: {
    ...mapState(["selectedLenguaje"]),

  }, mounted() {
    this.$nextTick(() => {
      console.debug(this.selectedLenguaje)
      if (!this.selectedLenguaje) {
        this.dialogLenguaje = true;
      }
    })
  }
  ,methods:{
    ...mapActions([
      "_selectedLenguaje",
      "_language"
    ]),
    setLenguaje(){
      if (this.toogle) {
      if (this.toogle == 'es') {
        this._language('es')
        this._selectedLenguaje(true)
        this.dialogLenguaje = false;
      }else{
        this._language('en')
        this._selectedLenguaje(true)
        this.dialogLenguaje = false;
      }
        
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>