
<template>
  <div>
    <v-app-bar style="position: fixed; z-index: 100" color="#fff" class="menu-app"
      scroll-target="#scrolling-techniques-7">
      <v-app-bar-nav-icon class="d-none-desktop" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-container>
        <div class="wrap-logo-menu">
          <img src="@/assets/LOGOGPS.svg" height="40px" />
          <ul class="menu-list">
            <li v-for="(i, index) in menu" :key="index">
              <!-- <router-link to="/#home">Home</router-link> -->
              <a :href="i.link">{{ i.text }}</a>
            </li>

          </ul>
          <v-spacer></v-spacer>
          <v-btn fab x-small class="mr-3 d-xs-none" rounded elevation="0" color="#164a7f" @click="$emit('openDialog')">
            <v-icon color="#fff">
              mdi-translate

            </v-icon>
          </v-btn>
          <div class="menu">
            <v-btn color="primary" rounded elevation="0" @click="dialog = true">
              {{ language == 'en' ? 'Get in touch' : 'Contáctanos' }}
            </v-btn>
          </div>
        </div>
      </v-container>
      <v-dialog v-model="dialog" v-if="dialog" width="500px">
        <formGetInTouch @close="dialog = false" />
      </v-dialog>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute left style="z-index: 100; position: fixed" height="100vh"
      class="d-none-desktop">
      <div class="wrap-btn-close ma-2">
        <v-btn icon large outlined @click="drawer = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>

        <v-btn fab x-small class="mr-3" rounded absolute right elevation="0" color="#164a7f" @click="$emit('openDialog')">
            <v-icon color="#fff">
              mdi-translate

            </v-icon>
          </v-btn>
      </div>
      <v-list nav dense class="mt-12">
        <v-list-item-group v-model="group" active-class=" text--accent-4 ">
          <v-list-item :href="i.link" v-for="(i, index) in menu" :key="index">
            <v-list-item-title>{{ i.text }}</v-list-item-title>
          </v-list-item>


        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
import alertMessage from "@/mixins/alertMixin";
import formGetInTouch from "@/components/formGetinTouch.vue";
import inputPhone from "@/components/inputPhone.vue";
export default {
  name: "menuBar",
  components: {
    inputPhone,
    formGetInTouch
  },
  mixins: [alertMessage],
  data() {
    return {
      dialog: false,
      form: {},
      phone: null,
      code: null,
      drawer: false,
      group: null,
    };
  },
  computed: {
    ...mapState(["selectedEvent", "user", "language"]),
    menu() {
      if (this.language == 'en') {
        const menu = [
          {
            link: '#Home',
            text: 'Home'
          },
          {
            link: '#about',
            text: 'About us'
          },
          {
            link: '#Discover',
            text: 'Discover Destinations'
          },
          {
            link: '#travelTips',
            text: 'Travel Tips'
          },

        ]
        return menu
      } else {
        const menu = [
          {
            link: '#Home',
            text: 'Inicio'
          },
          {
            link: '#about',
            text: 'Sobre nosotros'
          },
          {
            link: '#Discover',
            text: 'Descubre los destinos'
          },
          {
            link: '#travelTips',
            text: 'Consejos de viaje'
          },

        ]
        return menu
      }
    }


  },
  watch: {
    group(e) {
      console.debug(e);
      this.drawer = false;
    },
  },
  methods: {
    ...mapActions(["Alert_"]),
    valueInput(e, code) {
      // //console.debug(e, code);
      this.phone = e;
      this.code = code;
    },
    send() {
      var data = Object.assign({}, this.form);
      var phone = `${this.code.callingCodes[0]}${this.phone}`;
      data.phone = phone;
      console.debug(data);
      data.createdAt = new Date();
      db.collection("usersPending")
        .add(data)
        .then((response) => {
          console.debug(response);
          this.alertMessage("success", "Record saved successfully");
          this.dialog = false;
          this.phone = null;
          this.code = null;
          this.form = {};
        })
        .catch((error) => {
          console.debug(error);
          this.alertMessage("error", "unexpected error occurred");
          this.dialog = false;
          this.phone = null;
          this.code = null;
          this.form = {};
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/_responsive.scss";

.menu-app {
  z-index: 100;
  width: 100%;
  height: auto;
}

.wrap-logo-menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
}

.menu-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-left: 10px;

  @include responsive(mobile) {
    display: none;
  }

  li {
    float: left;

    a {
      text-decoration: none;
      color: #000;

      border-radius: 10px !important;
      padding-bottom: 2px;
      padding-top: 2px;
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 5px;
      transition: 0.3s ease-in-out;

      &:hover {
        color: #ffffff !important;
        background-color: #f78c23;
      }
    }
  }
}

.d-none-desktop {
  display: none;

  @include responsive(mobile) {
    display: block;
  }
}
.d-xs-none {
  display: block;
  @include responsive(mobile) {
    display: none;
  }
}
</style>