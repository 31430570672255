<template>
  <div class="home-container" id="Home">
    <div class="home-sections">
      <v-carousel :continuous="false" :cycle="cycle" :show-arrows="false" hide-delimiter-background
        delimiter-icon="mdi-minus" class="carrusel-main" height="500px">
        <v-carousel-item v-for="(slide, i) in sorthomeBanners" :key="i">
          <v-img :src="slide.url" height="100%">
            <v-sheet color="transparent" height="100%" tile>
              <v-row class="fill-height" align="center" justify="center">
              </v-row>
            </v-sheet>
          </v-img>
        </v-carousel-item>
      </v-carousel>
      <div class="wrap-search">
        <h1> {{ lang ? 'Your Latino travel guide to the United States' : 'Tu guía turística latina en Estados Unidos' }}
        </h1>
        <!-- <v-autocomplete clearable multiple rounded solo large prepend-inner-icon="mdi-magnify"
          placeholder="Buscar por nombre de ciudad, parque o sendero" light height="50px" max-width="100%">
        </v-autocomplete> -->
      </div>
    </div>
    <section id="about" class="my-12">
      <img src="@/assets/trama-01.svg" class="trama">
      <img src="@/assets/trama-02.svg" class="trama-02">

      <v-container>

        <v-row justify-content="center" class="flex-center" align-items="center">
          <v-col cols="12" sm="12" lg="4" md="4">
            <v-img style="border-radius: 10px" height="100%"
              src="@/assets/about.png">
            </v-img>
            <!-- <video autoplay width="100%">
              <source src="@/assets/home.mp4"  type="video/mp4" />
  
              Your browser does not support the video tag.
            </video> -->
          </v-col>
          <v-col cols="12" sm="12" lg="8" md="8" >

            <h1> {{ lang ? 'What is' : '¿Qué es' }} <span style="color: #f88932;">GPS Latino<span v-if="!lang">? </span></span></h1>
            <p v-if="lang">
              A different and authentic guide in Spanish that will help you find the destination you were looking for. We
              will visit the 50 states of the USA, to provide you with all the information you need to enjoy your next
              adventure to the fullest.
              GPS Latino is dynamic and attractive content that will be distributed in video capsule format on the main
              digital platforms.
            </p>
            <p v-else>
              Una guía diferente y auténtica en español que te ayudará a encontrar el destino que estabas buscando.
              Visitaremos los 50 estados de EEUU, para proporcionarte toda la información que necesitas para disfrutar al
              máximo de tu próxima aventura.
              GPS Latino es un contenido dinámico y atractivo que se distribuirá en formato cápsula de vídeo en las
              principales plataformas digitales.
            </p>
            <strong v-if="lang">
              Whether you travel alone, with family, or with friends,
              GPS Latino will be your best reference!
            </strong>
            <strong v-else>
              Tanto si viajas solo, en familia o con amigos,
              GPS Latino será su mejor referencia.
            </strong>
          </v-col>
        </v-row>
      </v-container>


    </section>
    <v-container id="Discover">
      <v-sheet class="mx-auto" elevation="0">
        <h1>
          <h1> {{ lang ? 'Discover Destinations (travel 1-day tours)' : 'Descubrir destinos (viajes de 1 día)' }} </h1>
        </h1>
        <v-slide-group v-model="model" center-active color="primary">
          <v-slide-item v-for="(n, index) in travels" :key="index" v-slot="{ active }">
            <v-card class="ma-4" outlined elevation="0" width="300px" @click="openDestinations(n)"
              style="border-radius: 10px">
              <video v-if="n.isVideo && n.videoCover" width="100%" webkit-playsinline="true" playsinline="true"
                onmouseover="this.play()" onmouseout="this.pause();this.currentTime=0;" class="video">
                <source :src="n.videoCover">
                </source>
              </video>
              <v-img :src="n.imageCover" v-else height="300px">
                <v-btn icon absolute top right>
                  <v-icon color="primary">mdi-bookmark</v-icon>
                </v-btn>
                <v-row class="fill-height" align="center" justify="center">
                  <v-scale-transition>
                    <v-icon v-if="active" color="white" size="48">mdi-close-circle-outline</v-icon>
                  </v-scale-transition>
                </v-row>
              </v-img>
              <v-card-title> {{ lang ? n.nameEn : n.name }} </v-card-title>
              <v-card-text>
                <v-row align="center" class="mx-0">
                  <v-rating :value="n.rating" color="primary" dense half-increments readonly size="14"></v-rating>

                  <div class="grey--text ms-4">{{ n.rating }}/5</div>
                </v-row>

                <!-- <div class="my-4 text-subtitle-1">{{ n.description }}</div> -->

                <div></div>
              </v-card-text>
              <v-card-actions>
                <v-btn block color="primary"> {{ lang ? 'learn more' : 'Saber más' }}   </v-btn>
              </v-card-actions>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </v-container>
    <section class="gale mt-12" id="travelTips">
      <v-container class="mt-12 mb-12">
        <v-row>
          <v-col cols="12" md="8" sm="12" lg="8" class="wrap-galery">
            <v-img v-for="(e, index) in travel" :key="index" height="150px" width="150px" class="ma-1"
              @click="openTravelTips(e)" style="border-radius: 10px" :src="e.url">
            </v-img>
          </v-col>
          <v-col cols="12" md="4" sm="12" lg="4" class="text-wrap">
            <h1>{{ lang ? 'Travel Tips' : 'Consejos de viaje' }} </h1>
            <p>
             {{ lang? 'Everything you need to know to plan your next trip':' Todo lo que necesitas saber para planificar tu próximo viaje' }}
            </p>
            <!-- <v-btn color="primary" rounded> Ver más </v-btn> -->
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-dialog v-model="dialogDestinations" v-if="dialogDestinations" fullscreen hide-overlay scrollable
      transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogDestinations = false">
            <v-icon> mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12
            " md="4
            " sm="4
            " lg="4
            " xl="4">
                <div class="wrap-stiky">
                  <video class="video-modal" v-if="selectDestinations.isVideo" :src="selectDestinations.videoCover"
                    autoplay controls style="border-radius: 10px; "></video>
                  <v-img v-else :src="selectDestinations.imageCover">

                  </v-img>
                </div>
              </v-col>

              <v-col cols="12" sm="8" lg="8" xl="8" class="py-6">
                <h1 class="mb-2"> {{ lang ? selectDestinations.nameEn : selectDestinations.name }}</h1>

                <v-rating :value="selectDestinations.rating" color="primary" dense half-increments readonly
                  size="14"></v-rating>
                <div class="mt-2" ref="descrip"></div>

                <div class="wrap-galery" v-if="selectDestinations.galery">
                  <v-img v-for="(e, index) in selectDestinations.galery" :key="index" height="150px" width="150px"
                    class="ma-1" style="border-radius: 10px" :src="e">
                  </v-img>
                </div>



              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-container class="actions">
            <v-spacer></v-spacer>
            <!-- <v-btn text @click="dialogDestinations = false">Cancel</v-btn> -->
            <v-btn color="primary" block @click="dialog = true">{{ lang ? 'Plan your trip' : 'Agenda tu viaje' }}</v-btn>
          </v-container>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogTravelTips" v-if="dialogTravelTips" scrollable transition="dialog-bottom-transition"
      width="500px">
      <v-card>
        <v-img :src="selectTravelsTips.url" height="300px">
          <v-btn fab absolute right small class="mt-2" @click="dialogTravelTips = false">
            <v-icon> mdi-close</v-icon>
          </v-btn>
        </v-img>

        <v-divider></v-divider>
        <v-card-text style="height: 100%;">
          <div class="mt-2" ref="descriptionsTips"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" v-if="dialog" width="500px">
      <formGetInTouch @close="dialog = false" />
    </v-dialog>

  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
import alertMessage from "@/mixins/alertMixin";
import inputPhone from "@/components/inputPhone.vue";
import formGetInTouch from "@/components/formGetinTouch.vue";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
export default {
  name: "home",
  mixins: [alertMessage],
  components: {
    inputPhone,
    formGetInTouch
  },
  data: () => ({
    model: null,
    dialogDestinations: false,
    selectDestinations: null,
    dialogTravelTips: false,
    selectTravelsTips: null,
    homeBanners: [],
    toDay: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    colors: [
      "green",
      "secondary",
      "yellow darken-4",
      "red lighten-2",
      "orange darken-1",
    ],
    cycle: false,
    slides: [
      "https://images.unsplash.com/photo-1518965723674-44cd4aff50d8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80",
      "https://images.unsplash.com/photo-1495571758719-6ec1e876d6ae?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80",
      "https://images.unsplash.com/photo-1552039431-11d2a516d0d4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80",
    ],
    travel: [],
    travels: [],
    dialog: false,
    form: {},
    phone: null,
    code: null,
  }),
  computed: {
    ...mapState(["language"]),
    lang() {
      return this.language == "en" ? true : false;
    },
    sorthomeBanners() {
      let arrayBanners = this.validSchedule(this.homeBanners);
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }
      return arrayBanners.sort(compare);
    },
  },
  methods: {
    ...mapActions(["Alert_"]),
    validSchedule(array) {
      console.debug(array);
      return array.filter((elemnet) =>
        moment(this.toDay).isBetween(
          moment(elemnet.dateStart.toDate()).format("YYYY-MM-DD"),
          moment(elemnet.dateEnd.toDate()).format("YYYY-MM-DD")
        )
      );
    },
    openDestinations(item) {
      this.selectDestinations = item;
      this.dialogDestinations = true;
      setTimeout(() => {
        const description = this.$refs.descrip;
        console.debug(description);
        if (this.lang) {
          description.innerHTML = item.descriptionEn || "";
        } else {
          description.innerHTML = item.description || "";
        }
      }, 500);
    },
    openTravelTips(item) {
      this.selectTravelsTips = item;
      this.dialogTravelTips = true;
      setTimeout(() => {
        const description = this.$refs.descriptionsTips;
        console.debug(description);
        if (this.lang) {
          description.innerHTML = item.descriptionEn || "";
        } else {
          description.innerHTML = item.description || "";

        }
      }, 500);
    },
    valueInput(e, code) {
      // //console.debug(e, code);
      this.phone = e;
      this.code = code;
    },

  },
  async mounted() {
    await this.$binding(
      "homeBanners",
      db
        .collection("banners")
        .where("deleted", "==", false)
        .where("active", "==", true)
    );
    this.$binding(
      "travel",
      db.collection("categories").where("active", "==", true)
    );
    this.$binding(
      "travels",
      db.collection("travels").where("active", "==", true)
    );
  },
};
</script>
<style lang="scss">
@import "@/_responsive.scss";

.home-sections {
  height: 500px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .carrusel-main {
    height: 100vh;
  }

  .wrap-search {
    position: absolute;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;

    h1 {
      font-size: 50px;

      text-shadow: 0px 0px 10px #00000069;
    }
  }
}

.wrap-galery {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-between;
}

.text-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

.gale {
  background-color: #f2f2f2;
  padding-bottom: 50px;
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

h1 {
  font-size: 36px;
}

.video {
  height: 300px;
  object-fit: cover;
}

.video-modal {
  width: 100%;
}

.wrap-stiky {
  position: sticky;
  top: 100px;
}

#about {
  position: relative;
}

.trama {
  position: absolute;
  right: -20px;
  width: 300px;
  bottom: 0;

  @include responsive(mobile) {
    display: none;
  }
}

.trama-02 {
  position: absolute;
  left: -20px;
  bottom: 0;
  width: 300px;

  @include responsive(mobile) {
    display: none;
  }
}

.actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.flex-center{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>