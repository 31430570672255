import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
Vue.use(Vuex);
const vuexPersist = new VuexPersist({
  key: "gpslatino-site",
  storage: window.localStorage,
  modules: [
    "language",
    "selectedLenguaje",
  ],
});

export default new Vuex.Store({
  state: {
    language:'en',
    selectedLenguaje:false,
    search: "",
    searchTerm: "",
    visibleSearch: true,
    business: null,
    selectedEvent: null,
    selectedCorporative: "",
    titlepage: "",
    darkmode: true,
    user: {},
    role: "",
    SnackbarAlert: {
      text: "",
      timeout: 2000,
      btn_closed: false,
      icon: false,
      type: "",
    },
  },
  mutations: {
    AlertSnackbar(state, config) {
      state.SnackbarAlert = config;
      return state.SnackbarAlert;
    },
    mode(state, mode) {
      state.darkmode = mode;
      return state.darkmode;
    },
    language(state, language) {
      state.language = language;
      return state.language;
    },
    selectedLenguaje(state, item) {
      state.selectedLenguaje = item;
      return state.selectedLenguaje;
    },
    setUser(state, target) {
      state.user = target;
      state.role = target.type;
    },
    setselectedEvent(state, target) {
      state.selectedEvent = target;
      state.selectedCorporative = target;
    },
    setAddNewCover(state, target) {
      state.selectedEvent.cover = target;
  
    },
    setBusiness(state, target) {
      state.business = target;
    },
    setAppFrame(state, target) {
      state.appFrame = target;
    },
    visibleSearch(state, target) {
      state.visibleSearch = target;
    },
    setSearch(state, target) {
      state.search = target;
    },
    setSearchTerm(state, target) {
      state.searchTerm = target;
    },
    settitlepage(state, target) {
      state.titlepage = target;
    },
    setNavBar(state, target) {
      state.navBar = target;
    },
    _searchTerm(state, target) {
      state.searchTerm = target;
    },
    setCorporative(state, target) {
      state.selectedCorporative = target;
    },
    setUserValidity(state, target) {
      state.userIsValid = target;
      setTimeout(() => {
        state.userIsValid = true;
      }, 3000);
    },
  },
  actions: {
    Alert_: ({ commit, state }, config) => {
      commit("AlertSnackbar", config);
    },
    mode: ({ commit, state }, mode) => {
      commit("mode", mode);
    },
    _language: ({ commit, state }, language) => {
      commit("language", language);
    },
    titlepage: ({ commit, state }, t) => {
      commit("settitlepage", t);
    },
    addCorporative: ({ commit, state }, business) => {
      commit("setCorporative", business);
    },
    _selectedLenguaje: ({ commit, state }, item) => {
      commit("selectedLenguaje", item);
    },
    addNewCover: ({ commit, state }, cover) => {
      commit("setAddNewCover", cover);
    },
    visibleSearch: ({ commit, state }, t) => {
      commit("visibleSearch", t);
    },
    addBusiness: ({ commit, state }, business) => {
      commit("setBusiness", business);
    },
    addEventSelection: ({ commit, state }, business) => {
      commit("setselectedEvent", business);
    },
    setUser: ({ commit, state }, user) => {
      commit("setUser", user);
    },

    _searchTerm: ({ commit, state }, dat) => {
      commit("_searchTerm", dat);
    },
    setUserValidity_: ({ commit, state }, i) => {
      commit("setUserValidity", i);
    },
  },
  getters: {
    Alert_: (state) => {
      return state.SnackbarAlert;
    },
    title: (state) => {
      return state.titlepage;
    },
    userIsValid: (state) => state.userIsValid,
    userType: (state) => state.user.type,
  },
  modules: {},
  plugins: [vuexPersist.plugin],
});
